@import (reference) '../../../styles/colors.less';

.email-recipients-list {
	&-autocomplete-search-field {
		.dropdown {
			display: block;
		}

		&-anchor {
			flex-grow: 1;
		}

		&-icon.search-icon {
			height: 16px;
			margin-right: 8px;
			width: 16px;
		}
	}

	&-items {
		overflow-y: auto;
		flex-grow: 1;
	}

	&-item {
		align-items: center;
		cursor: pointer;
		display: flex;
		padding: 12px 0;
		flex: 1;

		&-hidden-remove-button {
			opacity: 0;
			pointer-events: none;
			visibility: hidden;
		}

		&-avatar {
			border-radius: 50%;
			height: 60px;
			margin-left: 10px;
			width: 60px;
		}

		&-details {
			font-size: 14px;
			margin-left: 20px;
			width: ~'calc(100% - 150px)';

			&-name {
				color: @titles;
				font-size: 18px;
				line-height: 22px;
			}

			&-company-name {
				color: #858585;
				font-size: 14px;
				margin-top: 2px;
			}

			&-info {
				color: @brandPrimaryText;
				letter-spacing: 0;
				margin-top: 2px;

				&.email-customized {
					color: #89c947;
				}

				&.invalid-email {
					color: @error;
				}
			}
		}

		&-right-accessory {
			margin-right: 15px;
		}
	}
}
