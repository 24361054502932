@editorToolbarHeight: 39px;

.edit-email-template {
	&-body {
		.tox.tox-tinymce {
			overflow: inherit;

			.tox-editor-container {
				overflow: inherit;
	
				.tox-editor-header {
					background: #fff;
					border-bottom: 1px solid #ccc;
					position: -webkit-sticky;
					position: sticky;
					top: 0;
					z-index: 1;

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						// IE11 BS... no position: sticky
						position: fixed;
						top: auto;
						transform: ~"translate(0, -@{editorToolbarHeight})";
					}
				}
			}
		}

		&-field-editor {
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				// IE11 BS... no position: sticky
				margin-top: @editorToolbarHeight;
			}
		}
	}
}