@import (reference) '../../styles/colors.less';

.createAccount-highlight {
	font-weight: 600;
	color: #0d0f11;
}

.trialExpiringSoon-outerContainer {
	margin: 40px 10px 0 10px;
	border-radius: 0.75rem;
	padding-right: 5px;
	background: #fff;
	padding: 20px 0;

	& > *:not(:first-child) {
		border-top: 1px solid #cae0ec;
	}
}

.trialExpiringSoon-contentContainer {
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.trialExpiringSoon-inviteContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px 20px;
}

.trialExpiringSoon-inviteContainerText {
	font-size: 14px;
	color: #858585;
}

.trialExpiringSoon-inviteContainerLink {
	font-size: 14px;
	font-weight: 600;
	color: @brandSecondary;
	cursor: pointer;
}

.trialExpiringSoon-daysRemainingContainer {
	margin-top: 10px;
	margin-left: 10px;
}

.trialExpiringSoon-text {
	margin-top: 0;
	margin-bottom: 5px;
	color: #8e8e8e;
	font-size: 12px;
}

.trialExpiringSoon-textHighlight {
	font-size: 12px;
	color: #0d0f11;
	font-weight: 600;
}

.trialExpiringSoon-buttonContainer {
	text-align: center;
}

.purchaseButton {
	display: inline-block;
	background: white;
	font-size: 12px;
	padding: 2px 20px 2px 20px;
	text-align: center;
	color: @brandSecondary;
	cursor: pointer;
	border: 1px solid @brandSecondary;
	border-radius: 3px;
	cursor: pointer;
}

.trialExpired-innerContainer {
	width: 700px;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.trialExpired-headline {
	font-size: 18px;
	color: @brandSecondary;
	margin-top: 30px;
	margin-bottom: 30px;
}

.trialExpired-subHeadline {
	width: 500px;
	text-align: center;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 40px;
	color: #858585;
}

.trialExpired-buttonContainer {
	margin-bottom: 30px;
	text-align: center;
}

.trial-contact-support-link {
	text-decoration: none;
}

.purchase-contentContainer {
	margin: 25px;
	text-align: left;
}

.purchase-headlineContainer,
.purchase-planContainer {
	padding-bottom: 5px;
	margin-bottom: 20px;
	border-bottom: 1px solid #f0eded;
}

.purchase-headline {
	color: #0d0f11;
	font-size: 18px;
	margin-bottom: 20px;
}

.purchase-planNameContainer {
	display: flex;
}

.purchase-planNameIcon {
	width: 90px;
	height: 90px;
	margin-right: 22px;
}

.purchase-planNameTitle {
	color: black;
	font-size: 14px;
	margin-top: 15px;
	margin-bottom: 10px;
}

.purchase-planNameDescription {
	font-size: 14px;
	color: #858585;
}

.purchase-planDetailContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 30px;
}

.purchase-planDetails-users {
	font-size: 14px;
	color: #0d0f11;
}

.purchase-planDetails-users-label {
	margin-bottom: 5px;
}

.purchase-totalCostContainer {
	background-color: #e8f3f9;
	font-size: 14px;
	color: #858585;
	padding: 10px 50px 10px 50px;
}

.purchase-highlight {
	font-weight: 600;
	color: #0d0f11;
}

.purchase-formFieldLabel {
	color: #0d0f11;
	font-size: 14px;
	margin-bottom: 10px;
}

.purchase-formFieldLeftMargin {
	display: inline-block;
	margin-left: 10px;
}

.purchase-expirationAndCVVContainer {
	display: flex;
}

.marginLeft20px {
	margin-left: 20px;
}

.purchase-termsOfUseContainer {
	font-size: 12px;
	color: #858585;
}

.purchase-termsOfUseContainer a,
.purchase-termsOfUseContainer a:visited {
	color: @brandSecondary;
	text-decoration: none;
}

.purchase-termsOfUseContainer a:hover {
	text-decoration: underline;
}

.purchase-cancelLink {
	text-decoration: underline;
	color: #858585;
	font-size: 14px;
	display: inline-block;
	cursor: pointer;
	margin-left: 20px;
}

.credit-card-state-selectbox {
	font-size: 14px;
	width: 317px;
}
